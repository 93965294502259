import React from "react"
import { graphql } from "gatsby"
import { BLOCKS } from "@contentful/rich-text-types"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import galleryStyles from "./galleryPage.module.scss"
import Img from "gatsby-image/withIEPolyfill"

import Layout from "../components/layout"
import Head from "../components/head"

export const query = graphql`
  query($slug: String!) {
    contentfulGallery(slug: { eq: $slug }) {
      title
      date(formatString: "D/M/YYYY")
      body {
        json
      }
      gallery {
        id
        title
        description
        localFile {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`

const GalleryPage = props => {
  const options = {
    renderNode: {
      [BLOCKS.EMBEDDED_ASSET]: node => {
        const alt = node.data.target.fields.title["en-US"]
        const url = node.data.target.fields.file["en-US"].url
        return <img alt={alt} src={url} />
      },
    },
  }

  return (
    <Layout>
      <Head title={props.data.contentfulGallery.title} />
      <div className="columns is-desktop">
        <div className="column is-half-desktop is-6 is-offset-3 is-family-secondary ">
          <section className="hero">
            <div className={`hero-body ${galleryStyles.heroBody}`}>
              <div className="container pb-5">
                <h1 className="title">{props.data.contentfulGallery.title}</h1>
                <h2 className="subtitle"></h2>
              </div>
              <div className="content">
                {documentToReactComponents(
                  props.data.contentfulGallery.body.json,
                  options
                )}
              </div>
            </div>
          </section>
        </div>
      </div>
      {props.data.contentfulGallery.gallery
        ? props.data.contentfulGallery.gallery.map(pic => {
            return (
              <div
                className={`columns ${galleryStyles.bodyColumns} body-columns is-centered is-mobile`}
              >
                <div
                  className={`column is-three-quarters-mobile is-two-thirds-tablet is-half-desktop is-5-widescreen is-5-fullhd ${galleryStyles.columnS}`}
                >
                  <div className={`${galleryStyles.card} card`}>
                    <div className={`${galleryStyles.header} header`}>
                      <h4>{pic.title}</h4>
                    </div>
                    <div className="card-image">
                      <Img
                        Tag={"figure"}
                        className={"image"}
                        durationFadeIn={200}
                        fadeIn={true}
                        fluid={pic.localFile.childImageSharp.fluid}
                      />
                    </div>
                    <div className="card-content">
                      <div className="content">
                        {pic.description}
                        <br />
                        <p>{props.data.contentfulGallery.date}</p>
                      </div>
                    </div>
                    <div className={`${galleryStyles.cardFooter} card-footer`}>
                      <div className="columns is-mobile">
                        <div className="column is-11"></div>
                        <div className="column has-text-centered"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )
          })
        : ""}
      }
    </Layout>
  )
}

export default GalleryPage
